import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'
import Button from 'components/shared/button'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

import { PostThumbnail } from 'types/post'

const StyledContainer = styled(Container)<{ layout: 'homepage' | 'post' }>`
  padding: 80px 0 80px 0;

  ${media.lg.min} {
    padding: 120px 30px 120px 30px;
  }

  ${({ layout }) =>
    layout === 'post' &&
    css`
      padding: 40px 0 80px 0;

      ${media.lg.min} {
        padding: 40px 30px 100px 30px;
      }
    `}
`

const StyledPostCardsWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(1, 1fr);

  ${media.lg.min} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const StyledPostCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.pewterblue};

  ${media.lg.min} {
    button {
      visibility: hidden;
      opacity: 0;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }

    &:hover {
      button {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  a {
    display: flex;
    flex-direction: column;
  }

  &:nth-child(1n) {
    background-color: ${({ theme }) => theme.colors.pewterblue};
  }

  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.colors.jet};
  }

  &:nth-child(3n) {
    background-color: ${({ theme }) => theme.colors.midnightgreen};
  }
`

const StyledPostCardImageWrapper = styled.div`
  height: 200px;

  ${media.lg.min} {
    height: 240px;
  }
`

const StyledPostCardContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 25px 36px 30px 36px;

  ${media.lg.min} {
    padding: 40px 40px 25px 40px;
  }

  &:before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    height: 1px;
    left: 0;
    top: 35px;
    width: 21px;

    ${media.lg.min} {
      top: 50px;
      width: 26px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.white};
    height: 1px;
    right: 0;
    top: 35px;
    width: 120px;

    ${media.lg.min} {
      top: 50px;
      width: 125px;
    }
  }
`

const StyledTitle = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  line-height: 22px;

  padding-right: 89px;

  ${media.lg.min} {
    font-size: 22px;
    line-height: 30px;
  }
`

const StyledDateAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledDate = styled.p`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.white};
`

const StyledReadMoreButton = styled(Button)`
  margin-top: 30px;
  background: transparent;
  border-color: white;
  font-size: 16px;

  ${media.lg.min} {
    width: 50%;
    font-size: 18px;
  }
`

const StyledButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  padding: 0 36px;

  ${media.lg.min} {
    margin-top: 60px;
  }

  a {
    text-align: center;
  }
`

type Props = {
  layout: 'homepage' | 'post'
  heading: string
  buttonText: string
  posts: PostThumbnail[]
}

const BlogPosts: React.FC<Props> = ({ layout, heading, buttonText, posts }) => {
  const { lg } = useBreakpoint()

  if (layout === 'post' && !lg) {
    // eslint-disable-next-line
    posts = posts.slice(0, 1)
  }

  return (
    <StyledContainer layout={layout}>
      <Heading
        as="h3"
        dangerouslySetInnerHTML={{ __html: heading }}
        size={lg ? 36 : 24}
        sizeDiff={0}
        margin={lg ? '60px' : '40px'}
        align={lg ? 'left' : 'center'}
      />
      <StyledPostCardsWrapper>
        {posts?.map(({ title, slug, date, image }, index) => (
          <StyledPostCardWrapper
            as={Link}
            to={`/blog/${slug}/`}
            key={`${index}-${title}`}
          >
            <StyledPostCardImageWrapper>
              <LazyImage src={image.src} alt={image.alt} fill />
            </StyledPostCardImageWrapper>
            <StyledPostCardContentWrapper>
              <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
              <StyledDateAndButtonWrapper>
                <StyledDate>{date}</StyledDate>
                <StyledReadMoreButton>Czytaj więcej</StyledReadMoreButton>
              </StyledDateAndButtonWrapper>
            </StyledPostCardContentWrapper>
          </StyledPostCardWrapper>
        ))}
      </StyledPostCardsWrapper>
      <StyledButtonWrapper>
        <Button as={Link} to="/blog/">
          {buttonText}
        </Button>
      </StyledButtonWrapper>
    </StyledContainer>
  )
}

export default BlogPosts
