import React from 'react'
import styled from 'styled-components'
import { navigate, graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import Breadcrumbs from 'components/shared/breadcrumbs'
import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

import BlogPosts from 'components/blog-posts'

import media from 'styles/media'

import arrowIcon from 'assets/icons/arrow-dropdown.svg'

import { PostPageQuery } from 'types/graphql'

const IntroContainer = styled(Container)`
  position: relative;
`

const IntroContent = styled.div`
  max-width: 80ch;
  margin: 0 auto;
`

const StyledGoBackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-bottom: 40px;
  border: none;
  background: transparent;
  cursor: pointer;

  img {
    transform: rotate(180deg);
  }

  p {
    margin-left: 20px;
    font-weight: 700;
  }

  ${media.lg.min} {
    position: absolute;
    left: 30px;
  }
`

const StyledTitle = styled.h1`
  font-size: 24px;
  line-height: 1.4;
  margin-top: 20px;
  margin-bottom: 20px;

  ${media.lg.min} {
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 30px;
  }
`

const ContentContainer = styled(Container)`
  padding: 0 0 40px 0;

  ${media.lg.min} {
    padding: 0 30px 40px 30px;
  }
`

const Content = styled.div`
  max-width: 80ch;
  margin: 0 auto;
  padding: 0 36px;

  img {
    padding: 20px 0;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  p {
    margin-bottom: 20px;
    font-size: 17px;
    line-height: 25px;
  }

  h2 {
    line-height: 1.4;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 22px;
  }

  ${media.lg.min} {
    padding: 0;

    p {
      font-size: 19px;
      line-height: 27px;
    }

    h2 {
      font-size: 25px;
    }
  }
`

const Post: React.FC<PageProps<PostPageQuery>> = ({ data }) => {
  const POST = data?.wpPost
  const POST_SEO = data?.wpPost?.seo!

  const BLOG_POSTS =
    data?.allWpPost?.nodes?.map(({ title, slug, date, featuredImage }) => ({
      title: title!,
      slug: slug!,
      date: new Date(date).toLocaleDateString(),
      image: {
        src: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: featuredImage?.node?.altText!,
      },
    })) || []

  return (
    <Layout>
      <Seo
        title={POST_SEO.title ?? ''}
        description={POST_SEO.metaDesc ?? ''}
        ogTitle={POST_SEO.opengraphTitle}
        ogDescription={POST_SEO.opengraphDescription}
        ogImage={POST_SEO.opengraphImage?.sourceUrl}
        twitterTitle={POST_SEO.twitterTitle}
        twitterDescription={POST_SEO.twitterDescription}
        twitterImage={POST_SEO.twitterImage?.sourceUrl}
      />
      <Breadcrumbs
        spacing
        crumbs={[
          { name: 'Inspiracje', to: '/blog' },
          {
            name: POST?.title!,
          },
        ]}
      />

      <IntroContainer>
        <IntroContent>
          <StyledGoBackButton onClick={() => navigate(-1)}>
            <img src={arrowIcon} />
            <p>Wróć</p>
          </StyledGoBackButton>
          <Text>{new Date(POST?.date).toLocaleDateString()}</Text>
          <StyledTitle>{POST?.title}</StyledTitle>
        </IntroContent>
      </IntroContainer>

      <ContentContainer>
        <Content dangerouslySetInnerHTML={{ __html: POST?.content! }} />
      </ContentContainer>

      {BLOG_POSTS.length > 0 && (
        <BlogPosts
          layout="post"
          heading="Sprawdź także"
          buttonText="Zobacz wszystkie"
          posts={BLOG_POSTS!}
        />
      )}
    </Layout>
  )
}

export default Post

export const query = graphql`
  query PostPage($slug: String, $currentPostDate: Date) {
    wpPost(slug: { eq: $slug }) {
      seo {
        ...WpSEO
      }
      slug
      title
      date
      content
    }
    allWpPost(
      limit: 3
      filter: { slug: { ne: $slug }, date: { lt: $currentPostDate } }
    ) {
      nodes {
        title
        slug
        date
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
